import { GetFundamentalColumn } from '@/helpers/fundamentals';
import { Field, PredicateOrderSpec, PredicateType } from '@/queries/graphql-types';
import { Predicate } from '@/types/predicate';

type FieldDetailsType = {
    fundamentalColumnKey: string;
    type: 'PERCENTAGE' | 'BASIC' | 'BASIC_CURRENCY' | 'SCALED_CURRENCY';
};

export const FieldDetails: Partial<{
    [key in Field]: FieldDetailsType;
}> = {
    [Field.MarketCapitalizationUsd]: {
        fundamentalColumnKey: 'market_capitalization_usd',
        type: 'SCALED_CURRENCY',
    },
    [Field.Ebitda]: {
        fundamentalColumnKey: 'ebitda',
        type: 'SCALED_CURRENCY',
    },
    [Field.NetIncome]: {
        fundamentalColumnKey: 'net_income',
        type: 'SCALED_CURRENCY',
    },
    [Field.EarningsPerShare]: {
        fundamentalColumnKey: 'earnings_per_share',
        type: 'BASIC_CURRENCY',
    },
    [Field.OneYearAnnualRevenueGrowthRate]: {
        fundamentalColumnKey: 'one_year_annual_revenue_growth_rate',
        type: 'PERCENTAGE',
    },
    [Field.NetIncomeMargin]: {
        fundamentalColumnKey: 'net_income_margin',
        type: 'PERCENTAGE',
    },
    [Field.EbitdaMargin]: {
        fundamentalColumnKey: 'ebitda_margin',
        type: 'PERCENTAGE',
    },
    [Field.GrossIncomeMargin]: {
        fundamentalColumnKey: 'gross_income_margin',
        type: 'PERCENTAGE',
    },
    [Field.PriceEarningsRatio]: {
        fundamentalColumnKey: 'price_earnings_ratio',
        type: 'PERCENTAGE',
    },
    [Field.PriceToBookRatio]: {
        fundamentalColumnKey: 'price_to_book_ratio',
        type: 'PERCENTAGE',
    },
    [Field.PriceToSalesRatio]: {
        fundamentalColumnKey: 'price_to_sales_ratio',
        type: 'PERCENTAGE',
    },
    [Field.DebtToEquityRatio]: {
        fundamentalColumnKey: 'debt_to_equity_ratio',
        type: 'PERCENTAGE',
    },
    [Field.EnterpriseValueRevenueRatio]: {
        fundamentalColumnKey: 'enterprise_value_revenue_ratio',
        type: 'BASIC',
    },
    [Field.ReturnOnTotalCapital]: {
        fundamentalColumnKey: 'return_on_total_capital',
        type: 'PERCENTAGE',
    },
    [Field.ReturnOnAssets]: {
        fundamentalColumnKey: 'return_on_assets',
        type: 'PERCENTAGE',
    },
    [Field.ReturnOnTotalEquity]: {
        fundamentalColumnKey: 'return_on_total_equity',
        type: 'PERCENTAGE',
    },
    [Field.ReturnOnInvestedCapital]: {
        fundamentalColumnKey: 'return_on_invested_capital',
        type: 'PERCENTAGE',
    },
    [Field.DividendYieldDailyPercent]: {
        fundamentalColumnKey: 'dividend_yield_daily_percent',
        type: 'PERCENTAGE',
    },
    [Field.DividendPerShare]: {
        fundamentalColumnKey: 'dividend_per_share',
        type: 'BASIC_CURRENCY',
    },
    [Field.OneYearPricePerformance]: {
        fundamentalColumnKey: 'one_year_price_performance',
        type: 'PERCENTAGE',
    },
    [Field.ThreeYearPricePerformance]: {
        fundamentalColumnKey: 'three_year_price_performance',
        type: 'PERCENTAGE',
    },
    [Field.PriceThreeMonthSharpeRatio]: {
        fundamentalColumnKey: 'price_three_month_sharpe_ratio',
        type: 'BASIC',
    },
    [Field.PriceThreeMonthStandardDeviation]: {
        fundamentalColumnKey: 'price_three_month_standard_deviation',
        type: 'BASIC',
    },
    [Field.PriceThreeMonthRollingVolatility]: {
        fundamentalColumnKey: 'price_three_month_rolling_volatility',
        type: 'BASIC',
    },
    [Field.PriceThreeMonthDrawdown]: {
        fundamentalColumnKey: 'price_three_month_drawdown',
        type: 'BASIC',
    },
    [Field.CashConversionRatio]: {
        fundamentalColumnKey: 'cash_conversion_ratio',
        type: 'BASIC',
    },
};

const getComparatorString = (comparator: string): string => {
    switch (comparator) {
        case 'GT':
        case 'GTE':
            return 'min';
        case 'LT':
        case 'LTE':
            return 'max';
        default:
            return comparator;
    }
};

const getFieldInfo = (field: Field): [string, ReturnType<typeof GetFundamentalColumn>] => {
    const fieldDetails = FieldDetails[field];
    const fundamentalMapping = GetFundamentalColumn(fieldDetails?.fundamentalColumnKey || field.toLowerCase());
    return [fundamentalMapping?.displayLabel || field.toLowerCase(), fundamentalMapping];
};

const formatOrderSpec = (orderSpec: PredicateOrderSpec): [string, string] => {
    const [orderSpecField] = getFieldInfo(orderSpec.sort.field);
    return [
        orderSpecField,
        ` sort ${orderSpec.sort.direction.toLowerCase()}${
            orderSpec.limit ? ` limit ${orderSpec.limit.value}${orderSpec.limit.type === 'PERCENT' ? '%' : ''}` : ''
        }`,
    ];
};

const removeDuplicatesKeepOrder = (strings: Array<string>): Array<string> => {
    const seen = new Set<string>();
    return strings.filter((str) => {
        if (seen.has(str)) return false;
        seen.add(str);
        return true;
    });
};
const handleFieldOrderSpec = (
    predicateString: string,
    predicateField: Field,
    orderSpec?: PredicateOrderSpec | null
): Array<string> => {
    if (!orderSpec) return [predicateString];
    const [orderSpecField, orderSpecStr] = formatOrderSpec(orderSpec);
    if (predicateField && predicateField !== orderSpec.sort.field) {
        return removeDuplicatesKeepOrder([predicateString, `${orderSpecField}:${orderSpecStr}`]);
    }
    return removeDuplicatesKeepOrder([`${predicateString}${orderSpecStr}`]);
};

const appendOrderSpec = (predicateStrings: Array<string>, orderSpec?: PredicateOrderSpec | null): Array<string> => {
    if (!orderSpec) return removeDuplicatesKeepOrder(predicateStrings);
    const [orderSpecField, orderSpecStr] = formatOrderSpec(orderSpec);
    return removeDuplicatesKeepOrder([...predicateStrings, `${orderSpecField}:${orderSpecStr}`]);
};

const joinPredicates = (predicateStrings: Array<string>): string => {
    return predicateStrings.length === 1
        ? predicateStrings[0]
        : predicateStrings.map((str) => `(${str})`).join(' AND ');
};

export const stringsFromPredicate = (predicate: Predicate | null): Array<string> => {
    if (!predicate) return [];
    const traverse = (pred: Predicate): Array<string> => {
        switch (pred.type) {
            case PredicateType.ComparisonPredicate: {
                const [field, fundamentalMapping] = getFieldInfo(pred.field);
                const value = (fundamentalMapping?.formatFunc || ((x: string) => x))(pred.value);
                return handleFieldOrderSpec(
                    `${field}: ${value} ${getComparatorString(pred.comparator)}`,
                    pred.field,
                    pred.orderSpec
                );
            }
            case PredicateType.StatisticalPredicate: {
                const [field] = getFieldInfo(pred.field);
                const base = handleFieldOrderSpec(
                    `${field}: ${pred.statistic} ${getComparatorString(pred.comparator)}`,
                    pred.field,
                    pred.orderSpec
                );
                const context = pred.context ? joinPredicates(traverse(pred.context)) : null;
                return context ? removeDuplicatesKeepOrder([...base, context]) : base;
            }
            case PredicateType.InPredicate: {
                const [field] = getFieldInfo(pred.field);
                return handleFieldOrderSpec(
                    `${field}: ${
                        Array.isArray(pred.values)
                            ? pred.values.length === 1
                                ? pred.values[0]
                                : pred.values.join(', ')
                            : ''
                    }`,
                    pred.field,
                    pred.orderSpec
                );
            }
            case PredicateType.AndPredicate: {
                if (pred.predicates.length === 1) return appendOrderSpec(traverse(pred.predicates[0]), pred.orderSpec);
                const childStrings: Array<string> = pred.predicates.map((p) => joinPredicates(traverse(p)));
                return appendOrderSpec(childStrings, pred.orderSpec);
            }
            case PredicateType.OrPredicate: {
                const childStrings: Array<string> = pred.predicates.map((p) => `(${joinPredicates(traverse(p))})`);
                return appendOrderSpec([`${childStrings.join(' OR ')}`], pred.orderSpec);
            }
            case PredicateType.GlobalContextPredicate: {
                const context = traverse(pred.context);
                const globalPred = traverse(pred.predicate);
                return appendOrderSpec([...context, ...globalPred], pred.orderSpec);
            }
            case PredicateType.SortLimitPredicate: {
                return appendOrderSpec([], pred.orderSpec);
            }
            default:
                throw new Error(`Unrecognized predicate: ${JSON.stringify(pred)}`);
        }
    };
    return traverse(predicate);
};
